/* eslint-disable max-lines */
export default {
  msg_app_action_reload: 'Przeładuj',
  msg_page_bulk_adverts_upload: 'Ładowanie pliku ogłoszenia',
  msg_error_invalid_advert_category: 'Nieprawidłowo wybrana kategoria ogłoszenia',
  msg_my_ads_bulk_upload_button: 'Załaduj plik ogłoszenia',
  msg_bulk_upload_page_hero_title: 'Załaduj kilka ogłoszeń jednocześnie!',
  msg_bulk_upload_page_hero_subtext:
    'Tutaj możesz załadować listę ogłoszeń, które będą publiczne lub widoczne tylko dla Ciebie.',
  msg_bulk_upload_form_title: 'Forma ładowania ogłoszeń',
  msg_bulk_upload_form_ad_privacy_field: 'Określ dostępność załadowywanych ogłoszeń',
  msg_bulk_upload_form_ad_privacy_public_type: 'Ogłoszenia publiczne',
  msg_bulk_upload_form_ad_privacy_private_type: 'Ogłoszenia widoczne tylko dla Ciebie',
  msg_bulk_upload_form_upload_file_field: 'Lista ogłoszeń (format .csv)',
  msg_btn_csv_upload: 'Załaduj',
  msg_button_submit_bulk_upload: 'Złóż',
  msg_error_csv_import_invalid_file_format: 'Nieprawidłowy format, załaduj plik .csv.',
  msg_bulk_upload_completed_title: 'Ogłoszenia załadowane pomyślnie!',
  msg_bulk_upload_completed_subtitle: 'Można je zobaczyć w sekcji "Moje ogłoszenia"',
  msg_error_csv_import_invalid_value:
    'Błąd w wierszu {{lineNumber}}: w kolumnie {{columnTitle}} wartość {{ value }} nie jest poprawna.',
  msg_error_csv_import_specify_error_location: 'Błąd w wierszu {{lineNumber}}: {{columnTitle}} kolumny.',
  msg_company_phone_label: 'tel.',
  msg_advert_create_advert_location_field_explanation: 'Podaj dokładny adres, aby ogłoszenie było widoczne na mapie.',
  msg_advert_create_advert_valid_until: 'Ważne do',
  msg_orders_map_no_results_found: 'Wyników nie znaleziono',
  msg_btn_submit_filters: 'Szukaj',
  msg_advert_create_status_selection_field_field_description: 'Ustaw dostępność ogłoszenia',
  msg_advert_create_status_selection_placeholder: 'Ustaw dostępność ogłoszenia',
  msg_ad_status_active_selection: 'Ogłoszenia publiczne',
  msg_ad_status_private_selection: 'Ogłoszenie prywatne',
  msg_order_total_order_price: 'Cena zamówienia',
  msg_order_item_order_date: 'Data zamówienia',
  msg_bank_info_updated_title_create: 'Informacje o przelewie bankowym zapisane!',
  msg_bank_info_updated_subtitle_create: 'Informacje te będzie można zmieniać.',
  msg_userpage_transfer_info_button_create: 'Dodaj informacje o przelewie bankowym',
  msg_error_invalid_iban: 'Nieprawidłowy numer rachunku.',
  btn_label_clear_filters: 'Wyczyść filtry',
  msg_btn_hide_location_bar: 'Zwiń filtry',
  msg_btn_show_location_bar: 'Rozwiń filtry',
  msg_label_authentication_tour_auth_step1:
    'Wypełnij pola rejestracji, podając swój adres e-mail, nazwę użytkownika i hasło.',
  msg_label_authentication_tour_auth_step2: 'Zaznacz, że zgadzasz się z naszą Polityką prywatności',
  msg_label_authentication_tour_auth_step3: 'Kliknij "Zarejestruj się" i zostań członkiem naszej społeczności!',
  msg_label_company_tour_company_step1: 'Wskaż swój status i uzupełnij informacje.',
  msg_label_company_tour_company_step2: 'Zapisz podane informacje.',
  msg_label_company_tour_company_step3: 'Wszystko! Możesz przejść na rynek i korzystać z usług i korzyści Sejico!',
  msg_company_advert_is_set_to_private: 'Ogłoszenie jest widoczne tylko w grupie!',
  msg_company_advert_private_status_title: 'Kliknij, aby wyświetlić reklamę członkom grupy',
  msg_company_advert_private_status_subtitle: 'Ogłoszenie będzie wyświetlane tylko członkom grupy.',
  msg_status_created_email: 'Otrzymałeś/-aś nowe zamówienie!',
  msg_status_canceled_email: 'Zamówienie zostało anulowane!',
  msg_status_unfinished_email: 'Zamówienie jest niedokończone!',
  msg_status_finished_email: 'Zamówienie wykonane!',
  msg_status_expired_email: 'Zamówienie zostało zakończone. Prosimy o złożenie nowego zamówienia.',
  msg_status_ended_email: 'Zamówienie zakończone!',
  msg_status_declined_email: 'Zamówienie zostało odrzucone!',
  msg_status_active_email: 'Zamówienie jest aktywne!',
  msg_status_accepted_email: 'Zamówienie zostało przyjęte!',
  msg_my_adverts_display_views_count: '{{viewCount}} wyświetleń',
  msg_thread_created_at_date: 'Utworzono dnia {{date}}',
  msg_order_id_in_confirm_services_received: 'Nr. {{orderId}}',
  msg_label_footer_text:
    'Projekt jest finansowany w ramach pierwszego priorytetu" "Promowanie badań naukowych, eksperymentalnych prac rozwojowych i innowacji" Programu Operacyjnego Inwestycji Funduszy Unii Europejskiej 2014-2020, wdrażającego działanie nr J05-LVPA-K "Intellect. Wspólne projekty nauki i biznesu".',
  msg_auction_bidders: 'bierze udział {{value}}',
  msg_auction_active_status_with_expiration: 'Pozostało {{value}}',
  msg_auction_active_status_no_expiration: '-',
  msg_label_ago: '{{value}}',
  msg_verification_email_title: 'Adres E-mail został potwierdzony!',
  msg_verification_email_subtitle: 'Możesz zacząć korzystać z Sejico!',
  msg_userpage_update_company_tab: 'Informacje o gospodarstwie',
  msg_userpage_update_billing_info_tab: 'Informacje o rachunku',
  msg_userpage_invoice_info_tab: 'Dokumentacja płatności',
  msg_userpage_company_reviews_tab: 'Moje oceny',
  msg_sort_adverts_option_advert_newest: 'Najnowszy na górze',
  msg_userpage_change_payments_tab: 'Karty płatnicze',
  msg_userpage_change_password_tab: 'Informacje o użytkowniku',
  msg_switcher_topic: 'Wybierz kierunek',
  msg_switcher_kind_agro: 'Sejico Agro',
  msg_switcher_kind_agro_short: 'Agro',
  msg_switcher_kind_constructions_short: 'Construction',
  msg_switcher_kind_constructions: 'Sejico Construction',
  msg_switcher_kind_agro_paragraph: 'Sprzęt rolniczy do wynajęcia i ogłoszenia',
  msg_switcher_kind_constructions_paragraph: 'Wynajem sprzętu budowlanego i ogłoszenia',
  msg_switcher_button_go: 'Przejdź',
  msg_tooltip_company_phone_title: 'Wprowadź swój numer telefonu',
  msg_tooltip_company_phone_subtitle: 'Format numeru telefonu +370.',
  msg_tooltip_company_name_title: 'Nazwa gospodarstwa',
  msg_sort_adverts_option_advert_lowest_price: 'Najniższa cena na górze',
  msg_tooltip_company_name_subtitle: 'Wprowadź nazwę gospodarstwa, która będzie widoczna dla innych użytkowników.',
  msg_tooltip_company_area_title: 'Wybierz kod działalności gospodarstwa.',
  msg_tooltip_company_area_subtitle: 'Wybierz aktywność, która najlepiej pasuje do Twojego gospodarstwa.',
  msg_ton_amount: 'Podaj cenę (zł/t)',
  msg_title_website_title: 'Sejico',
  msg_thread_your_thread_reply: 'Zamieść komentarz',
  msg_thread_reply_success_title: 'Dziękujemy za komentarz!',
  msg_thread_reply_logged_out_form: 'Komentować mogą tylko zalogowani użytkownicy',
  msg_thread_reply_author_info: 'Oryginalna wiadomość',
  msg_thread_posted_by_author: 'Autor {{author}}',
  msg_thread_posted_by: 'Autor',
  msg_thread_logged_out_create_form: 'Komentować mogą tylko zalogowani użytkownicy',
  msg_thread_created_at: 'Utworzone',
  msg_thread_add_thread_reply: 'Komentować',
  msg_sort_adverts_option_advert_highest_rating: 'Najwyżej oceniane na górze',
  msg_sort_adverts_option_advert_highest_price: 'Najwyższa cena na górze',
  msg_sort_adverts_desc_title: 'Według nazwy (Z-A)',
  msg_sort_adverts_by: 'Pokaż',
  msg_sort_adverts_asc_title: 'Według nazwy (A-Z)',
  msg_service_is_not_received_subheader: 'Usługi nie zostały wyświadczone lub towary nie zostały dostarczone.',
  msg_service_is_not_received_modal_button: 'Potwierdzam niezrealizowane zamówienie',
  msg_service_is_not_received_header: 'Zamówienie nie zostało wykonane!',
  msg_sent_orders_tab: 'Złożone zamówienia',
  msg_search_results: 'Wyniki wyszukiwania',
  msg_search_bar_small_placeholder: 'Szukaj',
  msg_search_bar_placeholder: 'Wprowadź wyszukiwane hasło',
  msg_reset_password_title: 'Wprowadź nowe hasło',
  msg_reset_password_success_title: 'Hasło zostało zmienione pomyślnie!',
  msg_reset_password_success_subtitle: 'Zaloguj się przy użyciu nowego hasła.',
  msg_calendar_next: 'Do przodu',
  msg_calendar_date: 'Data',
  msg_reset_password_subtitle: 'Hasło musi składać się z co najmniej 5 znaków. Co najmniej jeden znak musi być liczbą.',
  msg_register_terms_of_usage:
    'Przeczytałem i akceptuję <a href="/page/salygos" target="_blank"> Warunki użytkowania </a> i <a href="/page/privatumo-politika" target="_blank">Polityką prywatności</a> witryny.',
  msg_register_marketing_material: 'Wyrażam zgodę na otrzymywanie informacji przesyłanych w celach marketingowych.',
  msg_register_form_register: 'Zarejestruj się',
  msg_received_orders_tab: 'Otrzymane zamówienia',
  msg_payment_pay_with_selected_card: 'Dokonaj płatności wybraną kartą',
  msg_payment_no_cards_yet: 'Nie masz żadnych kart płatniczych.',
  msg_payment_card_number_display_format: '•••• •••• •••• {{last4}}',
  msg_btn_delete_credit_card: 'Usuń kartę',
  msg_payment_card_delete_success_modal_title_gerai: 'Karta usunięta!',
  msg_payment_card_delete_success_modal_title: 'Karta usunięta z listy',
  msg_payment_card_delete_modal_title: 'Czy na pewno chcesz usunąć kartę z listy?',
  msg_page_user: 'Moje ustawienia',
  msg_page_category: 'Kategoria',
  msg_page_register: 'Zarejestruj się',
  msg_page_not_found: 'Strona nie została znaleziona!',
  msg_page_new_advert: 'Utworzyć nowe ogłoszenie',
  msg_page_my_orders: 'Zamówienia',
  msg_page_my_favourites: 'Ulubione ogłoszenia',
  msg_page_my_auctions: 'Moje aukcje',
  msg_page_my_adverts: 'Moje ogłoszenia',
  msg_page_my_account: 'Moje konto',
  msg_page_marketplace: 'Targowisko',
  msg_page_login: 'Zaloguj się',
  msg_page_landing: '"Sejico - innowacyjna platforma łącząca wszystkie zainteresowane strony sektora rolnictwa',
  msg_page_intro: '"Sejico - innowacyjna platforma łącząca wszystkie zainteresowane strony sektora rolnictwa',
  msg_page_go_to_new_auction: 'Utwórz aukcję',
  msg_page_go_to_new_ad: 'Załaduj ogłoszenie',
  msg_page_forum: 'Zapytaj eksperta',
  msg_page_static: 'Strona statyczna',
  msg_page_forgot_password: 'Zapomniałeś/-aś hasło?',
  msg_page_faq_title: 'Najczęściej zadawane pytania',
  msg_page_contact: 'Łączność',
  msg_page_about: 'O nas',
  msg_page_company_orders: 'Zamówienia',
  msg_page_company_ads: 'Moje ogłoszenia',
  msg_page_calendar_title: 'Kalendarz',
  msg_page_auctions: 'Aukcje',
  msg_page_advert_title: 'Ogłoszenie',
  msg_page_activity_title: 'Działalność',
  msg_order_summary_title_order_was_declined: 'Zamówienie zostało odrzucone!',
  msg_order_summary_title_order_was_cancelled: 'Zamówienie zostało anulowane',
  msg_order_summary_title_order_was_accepted: 'Zamówienie potwierdzone!',
  msg_order_summary_subtitle_at_the_bottom:
    '*Płatność jest dokonywana między klientem a dostawcą lub sprzedawcą usługi/towaru zgodnie z porozumieniem.',
  msg_order_summary_start_date: 'Strona główna',
  msg_order_summary_price_ton: 'Cena wstępna (zł/t)',
  msg_order_summary_price_night: 'Cena wstępna (zł/dzień)',
  msg_order_summary_price_meter: 'Cena wstępna (zł/miesiąc)',
  msg_order_summary_price_liter: 'Cena wstępna (zł/l)',
  msg_order_summary_price_kg: 'Cena wstępna (zł/kg)',
  msg_order_summary_price_item: 'Cena zamówienia',
  msg_order_summary_price_he: 'Cena',
  msg_order_summary_price: 'Cena usługi',
  msg_order_summary_phone_number: 'Tel.:',
  msg_order_summary_order_was_finished: 'Zamówienie wykonane!',
  msg_order_summary_negotiable_price: 'Cena do uzgodnienia',
  msg_order_summary_location: 'Miejsce',
  msg_order_summary_header_title: 'Zamówienie w skrócie',
  msg_order_summary_free_item_price: 'Daruje',
  msg_order_summary_final_price: 'Cena końcowa',
  msg_order_summary_end_date: 'Koniec',
  msg_order_summary_duration: 'Okres',
  msg_order_summary_amount: 'Ilość',
  msg_orders_requested_orders: 'Wysłane',
  msg_orders_received_orders: 'Otrzymane',
  msg_orders_all_orders: 'Wszystkie',
  msg_order_review_your_rating: 'Oceń swoje zamówienie',
  msg_order_review_form_placeholder:
    'Zostaw komentarz na temat swojego zamówienia, który będzie widoczny dla innych użytkowników.',
  msg_order_review_create: 'Oceń swoje zamówienie!',
  msg_order_payment_type_placeholder: 'Wybierz metodę płatności',
  msg_order_payment_form_header: 'Informacje o karcie płatniczej',
  msg_order_in_confirm_services_received: 'Zamówienie',
  msg_order_formulate_order: 'Tworzenie zamówienia',
  msg_order_finish_title: 'Zamówienie zostało wykonane!',
  msg_order_finish_subtitle: 'Usługi nie zostały wyświadczone lub towary nie zostały dostarczone.',
  msg_order_edit_order_success: 'Zamówienie zostało zmienione pomyślnie!',
  msg_order_edit_description:
    'Tutaj możesz zostawić wiadomość dla sprzedawcy. Będzie ona widoczna dopiero po potwierdzeniu zamówienia.',
  msg_order_delivery_price: 'Cena dostawy',
  msg_order_delivery_option: 'Dostawa nie jest możliwa',
  msg_order_decline_title: 'Czy na pewno chcesz odrzucić zamówienie?',
  msg_order_decline_subtitle: 'Odrzucone zamówienie będzie widoczne na liście otrzymanych zamówień.',
  msg_order_date_in_confirm_services_received: 'Data zamówienia',
  msg_order_create_review_success: 'Dziękujemy! Twoja ocena jest wystawiona!',
  msg_order_create_order_to: 'Data do',
  msg_order_create_order_success: 'Zamówienie zostało pomyślnie złożone!',
  msg_order_create_order_from: 'Data od',
  msg_order_delivery_option_with_price: 'Cena wysyłki {{price}}',
  msg_order_create_order: 'Utwórz zamówienie',
  msg_order_create_item_amount: 'Podaj ilość (szt.)',
  msg_order_create_hectare_amount: 'Podaj powierzchnię działki (ha)',
  msg_order_confirm_services_received_header: 'Potwierdzenie wykonania zamówienia',
  msg_order_choose_preliminary_dates: 'Wybierz wstępne daty realizacji zamówienia',
  msg_order_card_payment_success: 'Zamówienie zostało pomyślnie opłacone!',
  msg_order_card_payment_form_submit: 'Złożyć',
  msg_order_card_payment_form_expiry_date_field: 'Karta jest ważna do',
  msg_order_card_payment_form_cvc_code_field: 'Kod CVC',
  msg_order_card_payment_form_card_number_field: 'Nr karty',
  msg_order_cancel_title: 'Czy na pewno chcesz anulować zamówienie?',
  msg_order_cancel_subtitle: 'Wybierz działanie poniżej',
  msg_order_advert_title_in_confirm_services_received: 'Nazwa ogłoszenia',
  msg_order_advert_summary_header_title: 'Streszczenie ogłoszenia',
  msg_order_advert_summary_description_title: 'Opis',
  msg_order_advert_company_in_confirm_services_received: 'Dostawca',
  msg_order_accept_title: 'Czy na pewno chcesz anulować zamówienie?',
  msg_no_activity_found: 'Brak wpisów',
  msg_navbar_icon_upload_ad: 'Załaduj ogłoszenie',
  msg_order_accept_subtitle: 'Zamawiający zostanie poinformowany o przyjęciu zamówienia.',
  msg_navbar_icon_profile: 'Profil',
  msg_navbar_icon_my_orders: 'Zamówienia',
  msg_navbar_icon_my_ads: 'Ogłoszenia',
  msg_navbar_icon_forum: 'Zapytaj eksperta',
  msg_my_adverts_availability_schedule_page: 'Harmonogram ogłoszeń',
  msg_my_adverts_availability_page_hero_title: 'Dostępność usługi',
  msg_my_adverts_availability_page_hero_subtitle: 'Tutaj można zobaczyć harmonogram dostępności usług/technik.',
  msg_meter_amount: 'Podaj ilość (szt.)',
  msg_login_form_login: 'Zaloguj się',
  msg_login_form_forgot_password: 'Zapomniałeś/-aś hasło?',
  msg_liter_amount: 'Ilość (l)',
  msg_landing_page_competitive_advantage_title_3: 'Przestrzeń do współpracy',
  msg_landing_page_competitive_advantage_title_2: 'Aplikacja mobilna',
  msg_landing_page_competitive_advantage_title_1: 'System zarządzania ogłoszeniami i zamówieniami',
  msg_landing_page_competitive_advantage_description_1:
    'Niezawodny, przyjazny dla użytkownika i przejrzysty system zarządzania ogłoszeniami i zamówieniami, umożliwiający wzajemną komunikację i reklamowanie swoich towarów i usług.',
  msg_landing_page_competitive_advantage_description_2:
    'Obecnie opracowywana jest aplikacja mobilna umożliwiająca wygodne zarządzanie ogłoszeniami i zamówieniami, komunikowanie się z dostawcami lub klientami oraz prowadzenie dyskusji na forum.',
  msg_landing_page_competitive_advantage_description_3:
    'Sejico to przestrzeń do dzielenia się pomysłami, poradami i omawiania bieżących kwestii związanych z rolnictwem.',
  msg_landing_page_competitive_advantage_section_title: 'Dlaczego Sejico?',
  msg_landing_featured_auctions_title: 'Zalecane aukcje',
  msg_landing_featured_adverts_title: 'Zalecane ogłoszenia',
  msg_landing_topical_adverts_title: 'Aktualne ogłoszenia',
  msg_label_week: 'Tydzień',
  msg_label_vegetables: 'Warzywa',
  msg_label_username: 'Imię użytkownika',
  msg_label_upload_main: 'Załaduj zdjęcie główne',
  msg_label_upload_file_description: 'Tutaj możesz umieścić zdjęcie główne. Dodatkowo możesz umieścić do 4 zdjęć.',
  msg_label_upload_file: 'Załaduj zdjęcie',
  msg_label_uploaded_image: 'Zdjęcie zostało załadowane.',
  msg_label_upload: 'Załaduj zdjęcie',
  msg_label_unfinished: 'Niedokończone',
  msg_label_telescopic_loader_rent: 'Wynajem ładowarek teleskopowych',
  msg_label_tillage: 'Wyorywanie',
  msg_label_to: 'Do',
  msg_label_tractors_rent: 'Wynajem traktorów',
  msg_label_trade: 'Handel',
  msg_label_transportation_services: 'Usługi transportowe',
  msg_label_targeted: 'Docelowe',
  msg_label_status_unfinished: 'Niedokończone',
  msg_label_status_payment_pending: 'Nieopłacone',
  msg_label_status_finished: 'Wykonane',
  msg_label_status_expired: 'Nieważne',
  msg_label_status_ended: 'Zakończone',
  msg_label_status_declined: 'Odrzucone',
  msg_label_status_created: 'Niezatwierdzone',
  msg_label_status_canceled: 'Anulowane',
  msg_order_payment_pending_finish: 'Zamówienie potwierdzone',
  msg_label_status_active: 'Aktywny',
  msg_label_status_accepted: 'Zatwierdzone',
  msg_label_sprayer_rent: 'Wynajem opryskiwaczy',
  msg_label_soil_preparation_for_sowing: 'Przygotowanie gleby do siewu',
  msg_label_service: 'Usługa',
  msg_label_sent_orders: 'Wysłane',
  msg_label_sell_tractors: 'Traktory',
  msg_label_seller: 'Sprzedawca',
  msg_label_sell_agregate: 'Agregaty',
  msg_label_see_more_auctions: 'Pokaż więcej aukcji',
  msg_label_see_more_adverts: 'Pokaż więcej Ogłoszeń',
  msg_label_seedlings: 'Sadzonki',
  msg_label_seeder_rent: 'Wynajem siewników',
  msg_label_section_related_adverts_title: 'Podobne ogłoszenia',
  msg_label_section_last_previewed_adverts_title: 'Wyświetlone ogłoszenia',
  msg_label_section_featured_adverts_title: 'Zalecane ogłoszenia',
  msg_label_scraper_rent: 'Wynajem kosiarek',
  msg_label_schedule_display: 'Pokaż kalendarz',
  msg_label_sale: 'Oferuje',
  msg_label_return_back: 'Wrócić',
  msg_label_rent: 'Wynajem',
  msg_label_register: 'Zarejestruj się',
  msg_label_received_orders: 'Otrzymane',
  msg_label_real_estate: 'Nieruchomość',
  msg_label_purchase: 'Poszukuje',
  msg_label_plows_rent: 'Wynajem pługów',
  msg_label_pesticide_spraying: 'Opryskiwanie pestycydami',
  msg_label_payment_pending: 'Oczekuje na płatność',
  msg_label_password: 'Hasło',
  msg_label_other_services: 'Inne usługi',
  msg_label_other_machinery_rent: 'Wynajem innego sprzętu',
  msg_label_other: 'Inne:',
  msg_label_order_unfinished: 'Zamówienie niedokończone!',
  msg_label_order_type_requested: 'Złożone',
  msg_label_order_type_received: 'Otrzymane',
  msg_label_order_status_created: 'Niezatwierdzone',
  msg_label_order_status_accepted: 'Zatwierdzone',
  msg_label_order_needs_payment: 'Zamówienie oczekuje na płatność!',
  msg_label_order_item_status: 'Status',
  msg_label_order_item_seller: 'Sprzedawca',
  msg_label_order_item_order_price: 'Cena',
  msg_label_order_item_order_created_at: 'Zamówienie utworzone',
  msg_label_order_item_buyer: 'Kupujący',
  msg_label_order_finished: 'Zamówienie wykonane!',
  msg_label_order_expired: 'Zamówienie nie jest już ważne!',
  msg_label_order_ended: 'Zamówienie zostało zrealizowane!',
  msg_label_order_declined: 'Zamówienie odrzucone!',
  msg_label_order_created: 'Otrzymałeś/-aś nowe zamówienie!',
  msg_label_order_canceled: 'Zamówienie zostało anulowane!',
  msg_label_order_active: 'Zamówienie aktywne!',
  msg_label_order_accepted: 'Zamówienie potwierdzone!',
  msg_label_or: 'LUB',
  msg_label_no_results: 'Brak wyników',
  msg_label_no_payment_cards: 'Nie masz zachowanych kart.',
  msg_label_new_chat_message: 'Nowa wiadomość',
  msg_label_natural: 'Osoba',
  msg_label_mushrooms: 'Grzyby',
  msg_label_month: 'Miesiąc',
  msg_label_milling: 'Frezowanie',
  msg_label_milk_products: 'Produkty mleczne',
  msg_label_meta_title: 'Sejico.lt',
  msg_label_meta_description:
    '"Sejico - innowacyjna platforma łącząca wszystkie zainteresowane strony sektora rolnictwa',
  msg_label_meat_products: 'Produkty mięsne',
  msg_label_manure_equipment_rent: 'Sprzęt do gospodarowania nawozem',
  msg_label_mandatory: 'Niezbędne',
  msg_label_machinery_repair: 'Naprawa maszyn',
  msg_label_machinery_rent: 'Wynajem osprzętu maszynowego',
  msg_label_login: 'Zaloguj się',
  msg_label_list_view: 'Lista',
  msg_label_legal: 'Przedsiębiorstwo',
  msg_label_last_previewed_adverts_no_adverts: 'Brak wyświetlonych ogłoszeń.',
  msg_label_job_ads: 'Oferty/poszukiwanie pracy',
  msg_label_good: 'Towar',
  msg_label_grains_products: 'Produkty zbożowe',
  msg_label_grid_view: 'Siatka',
  msg_label_hay_baling: 'Belowanie siana',
  msg_label_hay_rolls: 'Bele siana',
  msg_label_herbs: 'Zioła',
  msg_label_implements: 'Urządzenia',
  msg_label_invoice_file: 'Wyciąg',
  msg_label_invoice_number: 'Numer wyciągu',
  msg_label_eur_per_kilogram: 'zł/kg',
  msg_label_eur_per_liter: 'zł/l',
  msg_label_eur_per_meter: 'zł/miesiąc',
  msg_label_eur_per_night: 'zł/dzień',
  msg_label_eur_per_ton: 'zł/t',
  msg_label_expired: 'Nieważne',
  msg_label_farm: 'Gospodarstwo',
  msg_label_favorite: 'Zapamiętać',
  msg_label_favorited: 'Zapamiętane',
  msg_label_featured_auctions_no_auctions: 'Obecnie nie ma żadnych aktywnych aukcji.',
  msg_label_finished: 'Wykonane',
  msg_label_fish_products: 'Produkty rybne',
  msg_label_forum_thread_created: 'Utworzono dnia {{date}}',
  msg_label_from: 'Od',
  msg_label_fruits: 'Owoce',
  msg_label_fruit_trees: 'Drzewa owocowe',
  msg_label_functional: 'Funkcyjne',
  msg_btn_user_tour_next: 'Kolejny',
  msg_label_eur_per_item: 'zł/szt.',
  msg_label_eur_per_hour: 'zł/h',
  msg_label_eur_per_hectare: 'zł/ha',
  msg_label_eur: 'Eur',
  msg_label_ended: 'Zakończone',
  msg_label_email: 'E-mail',
  msg_label_edit_advert_title: 'Ogłoszenie zmienione!',
  msg_label_edit_advert_subtitle: 'Zmiany ogłoszenia zostały zapisane.',
  msg_label_default_select_placeholder: 'Wybierz',
  msg_label_declined: 'Odrzucone',
  msg_label_day: 'Dzień',
  msg_label_cultivators_rent: 'Wynajem kultywatorów',
  msg_label_cultivation: 'Kultywacja',
  msg_label_credit_card_payment_type: 'Karta kredytowa/debetowa',
  msg_label_credit_card: 'Karta kredytowa/debetowa',
  msg_label_created: 'Niezatwierdzone',
  msg_label_create_company_hectare: 'Powierzchnia gospodarstwa (ha)',
  msg_label_create_company_cattle: 'Liczba bydła',
  msg_label_construction_services: 'Usługi budowlane',
  msg_label_company_form_vat_code: 'Kod podatnika VAT',
  msg_label_company_form_phone: 'Tel.',
  msg_label_company_form_name: 'Nazwa',
  msg_label_company_form_hectares: 'Powierzchnia gospodarstwa (ha)',
  msg_label_company_form_farm_code: 'Kod gospodarstwa',
  msg_label_company_form_farm_activity: 'Główna działalność',
  msg_label_company_form_company_code: 'Kod przedsiębiorstwa',
  msg_label_company_created: 'Gospodarstwo zostało utworzone!',
  msg_label_combine_rent: 'Wynajem kombajnów',
  msg_label_canceled: 'Anulowane',
  msg_label_buyer: 'Kupujący',
  msg_label_billing_info_form_vat_code: 'Numer VAT (jeśli dotyczy)',
  msg_label_billing_info_form_name: 'Nazwa',
  msg_label_billing_info_form_company_code: 'Nr certyfikatu działalności firmy/osoby/osoby fizycznej.',
  msg_label_billing_info_form_adress: 'Adres',
  msg_label_berries: 'Jagody',
  msg_label_beekeping_products: 'Produkty pszczelarskie',
  msg_label_auction_new_bid_inform_creator: 'W aukcji złożono nową ofertę!',
  msg_calendar_day: 'Dzień',
  msg_calendar_event: 'Zapis',
  msg_label_auction_new_bid_inform_bidders: 'W aukcji złożono nową ofertę!',
  msg_label_auction_ended_inform_creator: 'Aukcja zakończona!',
  msg_label_auction_ended_inform_bidders: 'Aukcja zakończona!',
  msg_label_auction_declined: 'Aukcja odrzucona!',
  msg_label_auction_approved: 'Aukcja potwierdzona!',
  msg_label_analytics: 'Analityczne',
  msg_label_all_orders: 'Wszystkie',
  msg_label_agro_services: 'Usługi rolnicze',
  msg_label_agro_machinery: 'Maszyny rolnicze',
  msg_label_advert_id_label: 'Identyfikator ogłoszenia',
  msg_label_ad_status_inactive: 'Ogłoszenie nieaktywne',
  msg_label_ad_status_active: 'Ogłoszenie aktywne',
  msg_label_active: 'Aktywne',
  msg_label_accepted: 'Zatwierdzone',
  msg_label_agreed_price: 'Płatność na podstawie porozumienia',
  msg_label_credit_card_deletion_info: 'Jeśli usuniesz swoją kartę, nie będzie ona wyświetlana obok metod płatności',
  msg_label_add_payment_method: 'DODAJ METODĘ PŁATNOŚCI',
  msg_label_add_card: 'Dodaj kartę',
  msg_kilogram_amount: 'Podaj ilość (kg)',
  msg_item_amount: 'Pożądana ilość',
  msg_label_advert_tour_ad_step2:
    'Tutaj możesz określić ilość lub czas trwania zamówienia i wybrać preferowaną metodę płatności.',
  msg_label_advert_tour_ad_step3: 'System automatycznie oblicza wstępną cenę zamówienia.',
  msg_label_advert_tour_ad_step4:
    'Tutaj możesz zostawić wiadomość dla dostawcy, którą on otrzyma po potwierdzeniu zamówienia. Kliknij przycisk " Złóż", aby złożyć zamówienie.',
  msg_label_advert_tour_ad_step1: 'W tym oknie widoczne są szczegóły ogłoszenia.',
  msg_invite_user_success: 'Użytkownik zaproszony!',
  msg_invite_user_modal_title: 'Aby zaprosić użytkownika, wprowadź jego adres e-mail.',
  msg_helper_text_company_form_name_natural:
    'Jeśli faktura jest przeznaczona dla osoby fizycznej, należy podać jej imię i nazwisko.',
  msg_helper_text_company_form_name_company:
    'Prosimy o podanie nazwy firmy lub osoby, na którą ma zostać wystawiona faktura.',
  msg_helper_text_company_form_name: 'Wskaż nazwę, którą inni użytkownicy będą widzieć w ogłoszeniu.',
  msg_forum_threads_table_subtopic: 'Temat',
  msg_forum_threads_table_last_comment: 'Ostatni mówca',
  msg_forum_threads_table_comments: 'Komentarze',
  msg_forum_threads_table_comment_count: 'Przemówienia',
  msg_forum_threads_table_author: 'Autor',
  msg_forum_threads_page_title: 'Temat forum',
  msg_filters_label_categories: 'Kategorie',
  msg_filters_label_filters: 'Filtry',
  msg_filters_price_range_from_placeholder: 'Od',
  msg_filters_price_range_to_placeholder: 'Do',
  msg_forgot_password_sent_sub_title: 'Sprawdź swoje konto e-mail.',
  msg_forgot_password_sent_title: 'Hasło zostało wysłane!',
  msg_forgot_password_sub_title: 'Wprowadź swój adres e-mail, na który zostanie wysłane hasło.',
  msg_forgot_password_title: 'Zapomniałeś/-aś hasło?',
  msg_forum_replay_to_message: 'Cytować',
  msg_forum_thread_no_records_message: 'Brak komentarzy',
  msg_forum_thread_page_title: 'Wiadomości tematu na forum',
  msg_filters_distance: 'Odległość',
  msg_filters_field_group_label_add_type: 'Działanie ogłoszenia',
  msg_filters_field_group_label_price_type: 'Rodzaj ceny',
  msg_filters_field_group_label_service_type: 'Rodzaj ogłoszenia',
  msg_filters_fields_accepted_events: 'Aktywne',
  msg_filters_fields_adType_label_all: 'Wszystkie',
  msg_filters_fields_adType_label_sale: 'Oferuje',
  msg_filters_fields_adType_label_purchase: 'Poszukuje',
  msg_filters_fields_ad_service_type_label_all: 'Wszystkie',
  msg_filters_fields_ad_service_type_label_good: 'Towar',
  msg_filters_fields_ad_service_type_label_service: 'Usługa',
  msg_filters_fields_adType_label_offers: 'Oferuje',
  msg_filters_fields_adType_label_search: 'Poszukuje',
  msg_filters_fields_custom_events: 'Inne wydarzenia',
  msg_filters_fields_pending_events: 'Oczekuje na zatwierdzenie',
  msg_filters_fields_pricing_label_per_ha: 'zł/ha',
  msg_filters_fields_pricing_label_per_hour: 'zł/godz.',
  msg_filters_fields_pricing_label_per_item: 'zł/szt.',
  msg_filters_fields_pricing_label_per_kilogram: 'zł/kg',
  msg_filters_fields_pricing_label_per_liter: 'zł/l',
  msg_filters_fields_pricing_label_per_meter: 'zł/miesiąc',
  msg_filters_fields_pricing_label_per_night: 'zł/dzień',
  msg_filters_fields_pricing_label_per_ton: 'zł/t',
  msg_btn_create_invoice: 'Utwórz fakturę',
  msg_filter_field_group_label_price_range: 'Cena',
  msg_field_size: 'Powierzchnia (ha)',
  msg_favorite_no_adverts_found: 'Nie masz żadnych ulubionych ogłoszeń.',
  msg_favorite_adverts_header_name: 'Nazwa',
  msg_favorite_adverts_header_farm_name: 'Dostawca',
  msg_favorite_adverts_header_action: 'Działanie',
  msg_faq_contact_us:
    'Nie znalazłeś/-aś odpowiedzi? Skontaktuj się z nami  <a href="mailto:info@sejico.lt" ><span> info@sejico.lt </span></a>',
  msg_error_unexpected: 'Wystąpił nieoczekiwany błąd',
  msg_error_wrong_company_input: 'Wprowadzono nieprawidłowe informacje!',
  msg_error_company_phone_missing: 'Nie wprowadzono numeru telefonu firmy!',
  msg_internal_server_error: 'Wystąpił błąd. Spróbuj ponownie',
  msg_error_user_with_this_email_already_exists: 'Ten adres e-mail jest już zarejestrowany!',
  msg_error_username_exists: 'Ta nazwa użytkownika już istnieje!',
  msg_error_time_is_before_start_time: 'Czas zakończenia nie może być wcześniejszy niż czas rozpoczęcia!',
  msg_error_this_password_is_too_common: 'Hasło nie jest bezpieczne. Wybierz inne hasło.',
  msg_error_smaller_number: 'Cena musi być wyższa niż ostatnia podana cena.',
  msg_error_selected_day_is_before_today: 'Wybrana data nie może być wcześniejsza niż dzisiejsza.',
  msg_error_passwords_are_equal: 'Hasła nie mogą być takie same',
  msg_error_password_format: 'Hasło musi zawierać co najmniej jedną cyfrę i składać się z co najmniej 5 znaków.',
  msg_error_text_contains_number: 'Tekst nie powinien zawierać numeru telefonu',
  msg_error_over_max_size: 'Powierzchnia gospodarstwa nie może przekraczać {{maxSize}} ha',
  msg_error_over_max_price_amount: 'Podana cena jest zbyt wysoka, maksymalna cena to 21 474 836 zł.',
  msg_error_over_max_length: 'Można wprowadzić {{maxLen}} znaków.',
  msg_error_over_max_fraction_digit_length: 'Możesz wprowadzić maksymalnie {{maxLen}} cyfr po przecinku',
  msg_error_over_max_amount: 'Maksymalna ilość {{maxAmount}}',
  msg_error_second_number_should_be_grater: 'Druga liczba musi być większa',
  msg_error_order_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Zaloguj się,</h4></a><span>aby złożyć zamówienie.</span>',
  msg_error_order_dates_out_of_schedule: 'Nieprawidłowe daty! Sprawdź harmonogram!',
  msg_error_order_date_diff_too_short: 'Minimalny wybór - co najmniej 1 dzień.',
  msg_company_users_list_title: 'Lista użytkowników',
  msg_error_order_date_diff_invalid: 'Minimalny czas zamówienia 3 godziny.',
  msg_error_only_numbers_allowed: 'Tutaj można wprowadzać tylko liczby!',
  msg_error_not_whole_number: 'Można wprowadzać tylko liczby całkowite.',
  msg_error_min_text_length: 'Aby wyszukać, wprowadź co najmniej {{minLen}} znaków.',
  msg_error_min_amount: 'Wprowadź liczbę większą niż 0.',
  msg_error_invited_user_already_exists_in_company: 'Ten użytkownik jest już na Twojej liście.',
  msg_error_invalid_username: 'Nazwa użytkownika musi składać się z co najmniej 4 znaków.',
  msg_error_invalid_price_format: 'Tutaj można wprowadzać tylko liczby!',
  msg_error_invalid_phone: 'Nieprawidłowy numer telefonu.',
  msg_error_invalid_field: 'Nieprawidłowy format. Wprowadź +370...',
  msg_error_invalid_email: 'Nieprawidłowy adres e-mail',
  msg_error_invalid_date: 'Wprowadzono nieprawidłową datę',
  msg_error_invalid_credentials: 'Nieprawidłowe dane logowania',
  msg_error_invalid_amount_value: 'Liczba musi być większa niż 0.',
  msg_error_invalid_amount: 'Nieprawidłowa kwota!',
  msg_error_invalid_value: 'Nieprawidłowa kwota!',
  msg_error_invalid_advert_categories: 'Wybierz kategorię produktu lub usługi!',
  msg_error_incorrect_password: 'Nieprawidłowe hasło.',
  msg_error_image_size_too_large: 'Zdjęcie nie może być większe niż 5 MB.',
  msg_error_greater_number: 'Cena musi być niższa niż ostatnia oferowana cena.',
  msg_error_field_required: 'Pole obowiązkowe',
  msg_error_error_occurred: 'Wystąpił błąd. Zamknij stronę i spróbuj ponownie',
  msg_error_end_time_is_equal_start_time: 'Czas rozpoczęcia musi być wcześniejszy niż czas zakończenia',
  msg_error_end_time_is_before_start_time: 'Data zakończenia musi być późniejsza niż data rozpoczęcia',
  msg_error_dates_is_overlapping: 'Daty się pokrywają!',
  msg_error_dates_difference_too_small: 'Minimalny okres wynosi 3 godziny.',
  msg_error_auction_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Zaloguj się,</h4></a><span>aby wziąć udział w aukcji.</span>',
  msg_error_auction_create_only_for_logged_in_users: 'Zaloguj się, aby utworzyć aukcję.',
  msg_error_categories_details_wrong_format: 'Nieprawidłowy format danych kategorii',
  msg_custom_fields_unexpected_error_unknown: 'Wprowadzono nieprawidłowe informacje - niewłaściwy format pola.',
  msg_custom_fields_unexpected_error_unexpected_field:
    'Wprowadzono nieprawidłowe informacje - to pole nie jest przewidziane w tej kategorii.',
  msg_custom_fields_unexpected_error_should_be_empty_because_no_schema:
    'Nieprawidłowo wprowadzone informacje - w tej kategorii nie przewidziano dodatkowych pól.',
  msg_custom_fields_unexpected_error_invalid_string: 'Nieprawidłowe informacje - tutaj można wprowadzić tylko tekst.',
  msg_custom_fields_unexpected_error_invalid_boolean:
    'Nieprawidłowe informacje - to pole ma tylko wartości "tak" i "nie".',
  msg_custom_fields_error_required_field: 'Pole obowiązkowe',
  msg_custom_fields_error_more_than_max: 'Wartość nie może być mniejsza niż {{limit}}.',
  msg_custom_fields_error_more_than_exclusive_max: 'Wartość musi być mniejsza niż {{limit}}.',
  msg_custom_fields_error_min_length: 'Wprowadź co najmniej {{limit}} znaków.',
  msg_custom_fields_error_max_length: 'Można wprowadzić nie więcej niż {{limit}} znaków.',
  msg_label_order_status_active: 'Aktywny',
  msg_custom_fields_error_less_than_min: 'Wartość nie może być mniejsza niż {{limit}}.',
  msg_custom_fields_error_less_than_exclusive_min: 'Wartość musi być większa niż {{limit}}.',
  msg_error_custom_fields_invalid_integer: 'Można wprowadzać tylko liczby całkowite.',
  msg_custom_fields_error_invalid_floating_point: 'Można wprowadzać tylko liczby.',
  msg_create_thread_title_placeholder: 'Nazwa',
  msg_create_thread_header_title: 'Nowy temat',
  msg_create_thread_header_subtitle:
    'Forum to miejsce, w którym nasi członkowie spotykają się, aby nawiązywać kontakty oraz dzielić się doświadczeniami i poradami. Przed utworzeniem tematu upewnij się, że nie został on już utworzony. Pomoże to użytkownikom szybciej znaleźć odpowiednie dyskusje.',
  msg_create_thread_content_placeholder: 'Tekst',
  msg_create_order_ton_amount_placeholder: 'Ilość (t)',
  msg_create_order_meter_amount_placeholder: 'Ilość (m)',
  msg_create_order_liter_amount_placeholder: 'Określ żądaną ilość (l)',
  msg_create_order_kilogram_amount: 'Ilość (kg)',
  msg_create_order_hours_placeholder: 'godz.',
  msg_create_order_date_to_placeholder: 'Data',
  msg_create_order_date_from_placeholder: 'Data',
  msg_create_forum_thread_success_title: 'Temat utworzony',
  msg_create_forum_thread_success_subtitle: 'Dziękujemy za temat i życzymy ciekawej dyskusji!',
  msg_create_forum_thread_success_button: 'Zamknąć',
  msg_create_forum_thread_page_title: 'Utwórz temat na forum',
  msg_create_company_skip_this_step: 'Pominąć ten krok',
  msg_create_company_profile_title: 'Stwórz gospodarstwo!',
  msg_create_company_profile_subtitle: 'Informacje o gospodarstwie są potrzebne do składania zamówień.',
  msg_create_company_phone: 'Nr. tel.',
  msg_create_company_p_entity_code: 'Wybierz działalności gospodarstwa.',
  msg_create_company_name: 'Podaj nazwę gospodarstwa',
  msg_create_company_hectare: 'Powierzchnia gospodarstwa (ha)',
  msg_create_company_delete_your_account: 'Aby usunąć gospodarstwo, skontaktuj się z nami',
  msg_create_company_continue_creation_title: 'Gratulujemy! Rejestracja przebiegła pomyślnie!',
  msg_create_company_continue_creation_subtitle: 'Możesz zacząć korzystać z Sejico!',
  msg_calendar_event_update_success: 'Wpis zmieniony!',
  msg_calendar_all_day: 'Dzień',
  msg_cookies_text:
    'Nasza strona używa plików cookie (angl. cookies). Pliki cookie zapewniają sprawne funkcjonowanie strony internetowej i jej ulepszanie, dlatego niezbędne pliki cookie (techniczne, funkcjonalne i analityczne) są instalowane automatycznie. Klikając "Zgadzam się", wyrażasz zgodę na instalację i używanie plików cookie. Zgodę można wycofać w dowolnym momencie, zmieniając ustawienia przeglądarki i usuwając zapisane pliki cookie. Więcej informacji można znaleźć <a href="/page/privatumo-politika" target="_blank"><span> w „Polityce pruwatności“</span></a>.',
  msg_cookies_settings: 'Ustawienia plików cookie',
  msg_cookies_modal_text:
    'Więcej informacji na temat plików cookie można znaleźć w naszej <a href="/page/privatumo-politika" target="_blank">polityce prywatności</a>.',
  msg_cookies_modal_header: 'Ustawienia plików cookie',
  msg_cookies_header: 'Pliki cookie używane przez Sejico',
  msg_contact_page_mobile_label: 'Skontaktuj się telefonicznie: <p><a href="tel:+37060020585">tel:+37060020585</a></p>',
  msg_contact_page_heading: 'Skontaktuj się z nami',
  msg_contact_page_facebook_label:
    'Śledź aktualności i komunikuj się z nami na naszym <a href="https://www.facebook.com/Sejico-102719424809555" target="_blank"> koncie Facebook </a>',
  msg_contact_page_email_label: 'Pytania ogólne: <p><a href="mailto:info@sejico.lt">info@sejico.lt</a></p>',
  msg_contact_page_description: 'Dbamy o członków naszej społeczności i jesteśmy tutaj, aby Ci pomóc!',
  msg_billing_info_updated_title_edit: 'Informacje zostały pomyślnie zaktualizowane!',
  msg_billing_info_updated_subtitle_edit: 'Informacje te zostaną wykorzystane do wygenerowania faktury.',
  msg_billing_info_updated_title_create: 'Informacje zostały zapisane!',
  msg_billing_info_updated_subtitle_create: 'Informacje te zostaną wykorzystane do wygenerowania faktury.',
  msg_company_users_info_tab: 'Uczestnicy gospodarstwa',
  msg_company_updated_title: 'Informacje zostały pomyślnie zaktualizowane!',
  msg_company_updated_subtitle: 'Zmiany zostały zapisane.',
  msg_company_reviews: 'Oceny użytkowników',
  msg_company_orders_page: 'Moje zamówienia',
  msg_company_orders_header_client: 'Zamawiający',
  msg_company_orders_filter_all: 'Wszystkie',
  msg_company_no_orders_sent: 'Brak zamówień',
  msg_company_no_orders_requested: 'Brak zamówień',
  msg_company_no_auctions: 'Nie jesteś jeszcze uczestnikiem i nie utworzyłeś/-aś aukcji.',
  msg_company_my_company_reviews: 'Wycena gospodarstwa',
  msg_company_joined_label: 'Data rejestracji',
  msg_company_form_modal_title: 'Informacje o nowym gospodarstwie',
  msg_company_empty_reviews: 'Brak ocen.',
  msg_company_edit_action: 'Edytuj',
  msg_company_customer_support_email: 'info@sejico.lt',
  msg_company_calendar_action: 'Kalendarz',
  msg_company_advert_is_hidden: 'Ogłoszenie ukryte!',
  msg_company_advert_invisible_status_title: 'Kliknij, aby ukryć ogłoszenie.',
  msg_company_advert_invisible_status_subtitle:
    'Ogłoszenie będzie widoczne tylko dla Ciebie, ale możesz je ponownie aktywować w dowolnym momencie.',
  msg_company_ads_visibility_title: 'Kliknij, aby ogłoszenie było widoczne.',
  msg_company_ads_visibility_description:
    'Aktywne ogłoszenie będzie widoczne dla wszystkich użytkowników. Ogłoszenie można zawsze ukryć.',
  msg_company_ads_header_status: 'Status ogłoszenia',
  msg_join_company_title: 'Dołącz do wspólnego gospodarstwa!',
  msg_company_ads_header_stats: 'Wyświetlenia ogłoszeń',
  msg_company_update_advert_top_title: 'Edytuj ogłoszenie',
  msg_company_preview_action: 'obacz ogłoszenie',
  msg_company_advert_is_visible: 'Ogłoszenie widoczne!',
  msg_company_empty_ads: 'Nie masz żadnych ogłoszeń.',
  msg_company_ads_header_price: 'Cena ogłoszenia',
  msg_company_ads_header_name: 'Nazwa ogłoszenia',
  msg_company_ads_header_actions: 'Działanie ogłoszenia',
  msg_company_ads_filter_placeholder: 'Pokaż',
  msg_company_ads_filter_inactive: 'Nieaktywne',
  msg_company_ads_filter_all: 'Wszystkie',
  msg_company_ads_filter_active: 'Aktywne',
  msg_company_active_ads: 'Ogłoszenia aktywne',
  msg_change_username_success: 'Nazwa użytkownika została zmieniona!',
  msg_change_username_subtitle: 'Twoja nazwa użytkownika',
  msg_change_username_new_username_placeholder: 'Wprowadź nową nazwę użytkownika',
  msg_change_username_heading: 'Zmiana nazwy użytkownika',
  msg_change_password_success: 'Hasło zostało zmienione!',
  msg_change_password_subheading: 'Nieprawidłowy adres e-mail',
  msg_change_password_old_password_placeholder: 'Wprowadź stare hasło',
  msg_change_password_new_password_placeholder: 'Wprowadź nowe hasło',
  msg_calendar_no_events_in_range: 'Nie znaleziono żadnych wydarzeń.',
  msg_calendar_previous: 'Powrót',
  msg_calendar_show_more: 'Pokaż więcej',
  msg_calendar_time: 'Czas:',
  msg_calendar_title: 'Ogłoszenia',
  msg_categories_btn: 'Kategorie',
  msg_calendar_today: 'Dzisiaj',
  msg_calendar_tomorrow: 'Jutro',
  msg_calendar_week: 'Tydzień',
  msg_calendar_work_week: 'Tydzień roboczy',
  msg_calendar_yesterday: 'Wczoraj',
  msg_change_password_heading: 'Zmiana hasła',
  msg_calendar_event_description: 'Opis',
  msg_calendar_event_edit: 'Zapisz',
  msg_calendar_event_end_date: 'Data zakończenia',
  msg_calendar_event_end_time: 'Czas zakończenia',
  msg_calendar_event_modal_edit_title: 'Edytuj wpis',
  msg_calendar_event_modal_title: 'Nowy wpis',
  msg_calendar_event_start_date: 'Data rozpoczęcia',
  msg_calendar_event_start_time: 'Czas rozpoczęcia',
  msg_calendar_event_title: 'Nazwa wpisu',
  msg_calendar_month: 'Miesiąc',
  msg_calendar_event_cancel_changes: 'Anuluj',
  msg_calendar_event_create_success: 'Zapis utworzony!',
  msg_calendar_event_default_title: 'Zapis',
  msg_btn_make_company_active: 'Aktywizacja gospodarstwa',
  msg_btn_modal_close: 'Zamknij',
  msg_btn_not_found: 'Przejdź do targowiska',
  msg_btn_ok: 'Dobrze',
  msg_btn_order_decline: 'Odrzuć',
  msg_btn_order_edit_accept: 'Zatwierdż',
  msg_btn_order_edit_cancel: 'Anuluj',
  msg_btn_order_finish: 'Zakończ zamówienie',
  msg_btn_order_no: 'Zamówienie nie zostało złożone',
  msg_btn_preview_add: 'Przeglądanie',
  msg_btn_preview_in_map: 'Pokaż na mapie',
  msg_btn_search: 'Szukaj',
  msg_btn_send: 'Wyślij',
  msg_btn_slider_cta: 'Zobacz oferty',
  msg_btn_submit: 'Złóż',
  msg_btn_submit_new_thread: 'Opublikuj',
  msg_btn_thread_reply_success_button: 'Zamknij',
  msg_btn_update_advert_form_submit: 'Zapisz zmiany',
  msg_btn_update_form: 'Zmień informacje',
  msg_btn_verification: 'Dobrze',
  msg_btn_view_company_order: 'Przeglądanie',
  msg_calendar_add_custom_event: 'Utwórz zapis',
  msg_calendar_agenda: 'Plan',
  msg_btn_logout: 'Wylogowanie',
  msg_btn_login_google: 'Google',
  msg_btn_login_fb: 'Facebook',
  msg_btn_invite_user_modal: 'Zaproś',
  msg_btn_invite_user: 'Zaproś użytkownika',
  msg_btn_go_back: 'Wrócić',
  msg_btn_filters_modal_search: 'Szukaj',
  msg_btn_filters_modal: 'Filtry',
  msg_btn_fetch_more_items: 'Pokaż więcej',
  msg_btn_edit_order: 'Zmień zamówienie',
  msg_btn_edit_advert_modal: 'Dobrze',
  msg_btn_edit_advert: 'Edytuj ogłoszenie',
  msg_btn_create_forum_thread: 'Zadaj pytanie',
  msg_btn_ask_experts_button: 'Nasi eksperci',
  msg_experts_page_title: 'Nasi eksperci',
  msg_btn_done: 'Zamknij',
  msg_btn_create_company: 'Stwórz gospodarstwo',
  msg_btn_create_advert_form_submit: 'Utwórz ogłoszenie',
  msg_btn_company_orders_go_to_marketplace: 'Przejdź do targowiska',
  msg_btn_continue: 'Kontynuuj',
  msg_btn_continue_company_creation: 'Rozpocznij',
  msg_btn_cookies_accept: 'Zgadzam się',
  msg_btn_cookies_accept_all: 'Akceptuj wszystkie pliki cookie',
  msg_btn_change_order_status_finished: 'Potwierdzam realizację zamówienia',
  msg_btn_cookies_modal_btn_accept: 'Zapisz ustawienia plików cookie',
  msg_btn_create_additional_company: 'Utwórz nowe gospodarstwo',
  msg_btn_accept_order: 'Potwierdź zamówienie',
  msg_btn_add_card_success: 'Karta dodana!',
  msg_btn_add_image: 'Załaduj zdjęcie',
  msg_btn_add_new_add: 'Załaduj nowe ogłoszenie',
  msg_btn_add_schedule: 'Dodaj czas',
  msg_btn_auction_created_modal: 'Utwórz nową aukcję',
  msg_btn_cancel: 'Anuluj',
  msg_btn_cancel_action: 'Powrót',
  msg_btn_cancel_order: 'Anuluj zamówienie',
  msg_btn_change: 'Zmienić',
  msg_auctions_filter_value_label: 'Filtr',
  msg_auctions_participating_tab: 'Uczestniczę',
  msg_auctions_sort_by_expiration_date: 'Najmniej czasu pozostało:',
  msg_auctions_sort_by_expiration_date_desc: 'Najwięcej czasu pozostało:',
  msg_auction_starting_price: 'Cena początkowa',
  msg_auction_total_bids: 'Uczestniczy',
  msg_auction_waiting_for_approval_status: 'Niezatwierdzone',
  msg_auction_winner_information_text: 'wygrał/-a aukcję. Cena końcowa',
  msg_billing_info_updated_subtitle: 'Faktura zostanie wygenerowana na podstawie zapisanych informacji.',
  msg_billing_info_updated_title: 'Dane faktury zostały pomyślnie zmienione!',
  msg_auction_create_save_auction_btn: 'Utwórz aukcję',
  msg_auction_create_title_field_placeholder: 'Nazwa',
  msg_auction_create_type_buy: 'Kupuję',
  msg_auction_create_type_sell: 'Sprzedaję',
  msg_auction_declined_status: 'Odrzucone',
  msg_auction_delete_modal_title: 'Czy na pewno chcesz usunąć aukcję?',
  msg_auction_delete_success_modal_title: 'Aukcja usunięta!',
  msg_auction_description_label: 'Opis',
  msg_auction_ended_status: 'Zakończona',
  msg_auction_expired_status: 'Nieważna',
  msg_auction_location_label: 'Miejscowość',
  msg_auction_no_bidders_placeholder: '-',
  msg_auction_page_hero_title: 'Załaduj aukcję',
  msg_auctions_created_tab: 'Utwórz',
  msg_auctions_filter_placeholder: 'Pokaż',
  msg_auction_create_bid_success_modal_title: 'Oferta cenowa złożona!',
  msg_auction_create_category_select_placeholder: 'Wybierz kategorię',
  msg_auction_create_description_field: 'Podaj jak najbardziej szczegółowy opis produktu.',
  msg_auction_create_description_field_placeholder: 'Opis',
  msg_auction_create_duration_field_description: 'Określ czas trwania aukcji w dniach.',
  msg_auction_create_duration_field_description_placeholder: 'Wybierz czas trwania aukcji (w dniach)',
  msg_auction_create_image_field: 'Zdjęcia',
  msg_auction_create_price_field_description_when_purchase:
    'Aby przyciągnąć oferentów, zalecamy wybranie atrakcyjnej ceny początkowej.',
  msg_auction_create_price_field_placeholder: 'Podaj cenę początkową',
  msg_btn_decline_order: 'Odrzuć zamówienie',
  msg_btn_create_payment_card: 'Dodaj',
  msg_auction_bid_price: 'Ostatnia cena',
  msg_auction_breadcrumb: 'Aukcje',
  msg_auction_create_auction_main_information: 'Informacje podstawowe',
  msg_auction_create_auction_price_and_time: 'Cena i czas trwania',
  msg_auction_create_auction_top_title: 'Informacje o aukcji',
  msg_auction_create_bid_amount_placeholder: 'Podaj cenę',
  msg_auction_create_bid_place_amount_btn: 'Zaproponuj cenę',
  msg_auction_create_bid_purchase_amount_description: 'Aby wziąć udział w aukcji, podaj niższą cenę.',
  msg_auction_create_bid_sale_amount_description: 'Aby wziąć udział w aukcji, podaj wyższą cenę.',
  msg_advert_display_no_my_ads_found: 'Brak ogłoszeń',
  msg_advert_event_delete_modal_title: 'Czy na pewno chcesz usunąć wpis?',
  msg_advert_event_delete_success_modal_title: 'Wpis usunięty!',
  msg_advert_images_field: 'Zdjęcia',
  msg_advert_page_hero_subtitle:
    'Umieść nieograniczoną liczbę ogłoszeń. Aby zamieścić ogłoszenie o swoim produkcie lub usłudze, wypełnij poniższy formularz.',
  msg_advert_page_hero_title: 'Żadnych opłat za umieszczanie ogłoszeń!',
  msg_advert_pricing_type_free_item_price: 'Daruje',
  msg_advert_pricing_type_negotiable_price: 'Cena do uzgodnienia',
  msg_advert_update_advert_save_advert_btn: 'Zmień ogłoszenie',
  msg_auction_auction_created_title: 'Aukcja utworzona!',
  msg_advert_create_service_type: 'Rodzaj ogłoszenia',
  msg_advert_create_service_type_good: 'Towar',
  msg_advert_create_service_type_service: 'Usługa',
  msg_advert_create_tab_additional_information: 'Dodatkowe informacje',
  msg_advert_create_tab_general_information: 'Informacje ogólne',
  msg_advert_create_title_field: 'Nazwa ogłoszenia',
  msg_advert_create_title_field_placeholder: 'Określ, co chcesz sprzedać lub kupić',
  msg_advert_delivery_type_no_delivery: 'Cena dostawy nie jest wliczona w cenę',
  msg_advert_delivery_type_optional: 'Dostępna opcja dostawy',
  msg_advert_delivery_type_required: 'Dostawa wliczona w cenę',
  msg_advert_display_ad_created_title: 'Gratulacje za stworzenie ogłoszenia!',
  msg_advert_display_advert_views_count: 'Wyświetlono',
  msg_advert_display_create_company_first: 'Aby złożyć zamówienie, utwórz gospodarstwo.',
  msg_ad_expired: 'Czas ogłoszenia zakończył się',
  msg_advert_create_advert_save_advert_btn: 'Utwórz ogłoszenie',
  msg_advert_create_advert_top_title: 'Forma ogłoszenia',
  msg_advert_create_advert_type: 'Cel ogłoszenia',
  msg_advert_create_advert_type_buy: 'Kup',
  msg_advert_create_advert_type_sell: 'Sprzedaj',
  msg_advert_create_delivery_price_field: 'Podaj cenę dostawy',
  msg_advert_create_delivery_price_field_description:
    'Podaj orientacyjną cenę dostawy, jeśli masz możliwość dostarczyć towary lub usługi.',
  msg_advert_create_delivery_price_field_placeholder: 'Podaj cenę dostawy',
  msg_advert_create_description_field: 'Opis',
  msg_advert_create_description_field_description:
    'Podaj wszelkie przydatne informacje o produkcie lub usłudze, warunkach dostawy itp.',
  msg_advert_create_description_field_placeholder: 'Opisz produkt lub usługę',
  msg_advert_create_other_information: 'Inne informacje',
  msg_advert_create_price_field: 'Określ cenę i jednostkę miary',
  msg_advert_create_price_field_placeholder: 'Podaj cenę',
  msg_advert_create_price_type_selection_placeholder: 'Wybierz typ ceny',
  msg_advert_create_schedule_enabled_field: 'Stwórz harmonogram dostępności usług',
  msg_add_auction_btn: 'Utwórz aukcję',
  msg_advert_additional_images_field: 'Dodatkowe zdjęcia',
  msg_advert_change_delivery_type: 'DOSTAWA',
  msg_advert_create_advert_category_select: 'Kategoria ogłoszenia',
  msg_advert_create_advert_category_select_placeholder: 'Wybierz kategorię z listy',
  msg_advert_create_advert_location_field: 'Wybierz miejsce',
  msg_advert_create_advert_location_field_placeholder: 'Miejscowość',
  msg_advert_create_advert_pricing_type_free_item: 'Daruje',
  msg_advert_create_advert_pricing_type_negotiable_price: 'Cena do uzgodnienia',
  msg_calendar_event_delete: 'Usuń wpis',
  msg_error_invalid_min_amount: 'Nieprawidłowa ilość.',
  msg_preview_my_auction_title: 'Zobacz aukcję',
  msg_landing_page_categories_header: 'Kategorie',
  msg_btn_login_apple: 'Sign in with Apple',
  msg_label_bank_transfer_payment_type: 'Przelew bankowy',
  msg_label_bank_transfer: 'Przelew bankowy',
  msg_order_transfer_payment_form_header_seller: 'Potwierdzenie przelewu bankowego',
  msg_order_transfer_received_header: 'Potwierdź, że otrzymałeś płatność przelewem bankowym',
  msg_btn_confirm_transfer_received: 'Potwierdź',
  msg_userpage_transfer_info_tab: 'Przelew bankowy',
  msg_userpage_transfer_info_form_company_name: 'Nazwa firmy/Imię, nazwisko',
  msg_userpage_transfer_info_form_iban: 'Numer rachunku',
  msg_userpage_transfer_info_form_bank_name: 'Nazwa banku',
  msg_userpage_transfer_info_button_update: 'Złóż',
  msg_bank_info_updated_title_edit: 'Informacje zostały zapisane!',
  msg_bank_info_updated_subtitle_edit: 'Informacje te można zmienić w ustawieniach.',
  msg_confirm_transfer_received_title: 'Potwierdzam otrzymanie płatności',
  msg_confirm_transfer_received_subtitle: 'Potwierdzam otrzymanie płatności przelewem bankowym za to zamówienie.',
  msg_confirm_transfer_received_btn: 'Potwierdź',
  msg_confirm_transfer_received_confirmed_title: 'Zamówienie zostało pomyślnie potwierdzone.',
  msg_error_only_letters_and_numbers_allowed: 'Można tu wprowadzać tylko litery i cyfry.',
  msg_label_order_payment_done: 'Płatność została wykonana!',
  msg_page_invitation_key_not_found: 'Link zaproszenia nie jest już ważny.',
  msg_btn_invitation_key_not_found: 'Zrozumiałem/-am',
  msg_page_join_company: 'Dołącz do wspólnego konta!',
  msg_page_switch_kind: 'Wybierz',
  msg_auction_create_new_auction: 'Utwórz aukcję',
  msg_label_auction_ended_inform_winner: 'Aukcja zakończona! Gratulujemy zwycięstwa!',
  msg_error_invalid_date_diff: 'Minimalny czas zamówienia 3 godziny.',
  msg_order_transfer_payment_form_header_buyer: 'Informacje o przelewie bankowym',
  msg_transfer_info_for_buyer_company_name: 'Nazwa firmy/Imię, nazwisko',
  msg_transfer_info_for_buyer_iban: 'Numer rachunku',
  msg_transfer_info_for_buyer_bank_name: 'Nazwa banku',
  msg_advert_additional_images_description: 'Możesz dodać 4 dodatkowe zdjęcia (do 5 MB).',
  msg_label_agreed_payment_type: 'Płatność na podstawie porozumienia',
  msg_auction_create_price_field_description_when_sell:
    'Aby przyciągnąć oferentów, zalecamy wybranie atrakcyjnej ceny początkowej.',
  msg_advert_create_schedule_enabled_field_description:
    'Aby określić konkretne godziny, w których usługa jest dostępna, zaznacz pole i dodaj daty i godziny. Bez ustalenia określonych godzin usługa będzie zawsze dostępna',
  msg_advert_main_image_field: 'Zdjęcie główne',
  msg_lt_lang: 'test',
  msg_preview_auction_title: 'Aukcja',
  msg_error_dates_out_of_schedule: 'Błędne daty, sprawdź kalendarz!',
  msg_error_invalid_advert_pricing_type: 'Nieprawidłowy format ceny',
  msg_open_preliminary_order: 'Złóż zapytanie',
  msg_submit_preliminary_order: 'Złóż',
  msg_cancel_preliminary_order: 'Anuluj',
  msg_create_preliminary_order: 'Złożyć wniosek',
  msg_order_form_is_disabled: 'Formularz zamówienia obecnie nie działa',
  msg_preliminary_order_start_date_older_today: 'Wybrana data nie może być wcześniejsza niż data dzisiejsza',
  msg_preliminary_order_email_not_valid: 'Nieprawidłowy adres e-mail',
  msg_preliminary_form_contact_method_missing: 'Wybierz metodę kontaktu',
  msg_preliminary_form_contact_only_one_method: 'Dostępny tylko jeden rodzaj kontaktu',
  msg_preliminary_form_phone_not_provided: 'Niepodany numer telefonu',
  msg_preliminary_form_email_not_provided: 'Niepodany adres e-mail',
  msg_preliminary_form_contact_platform_no_user: 'Użytkownik nie został znaleziony',
  msg_preliminary_order_creation: 'Otrzymaj ofertę',
  msg_preliminary_order_date: 'Data',
  msg_preliminary_order_comment: 'Twoje zapytanie',
  msg_preliminary_order_contact_type_title: 'Jak się z Tobą skontaktować?',
  msg_preliminary_order_contact_via_phone: 'Telefonicznie',
  msg_preliminary_order_contact_via_email: 'Pocztą elektroniczną',
  msg_preliminary_order_contact_via_platform: 'Za pośrednictwem platformy',
  msg_preliminary_order_phone: 'Nr telefonu',
  msg_preliminary_order_email: 'E-mail',
  msg_preliminary_order_name: 'Imię Nazwisko',
  msg_contact_phone: 'Skontaktuj się: {{phone}}',
  msg_preliminary_order_create_order_success: 'Zapytanie zostało złożone',
  msg_preliminary_order_create_order_success_subtext: 'Otrzymaliśmy Twoje zapytanie i wkrótce się z Tobą skontaktujemy',
  msg_rent_banner_title: 'Wynajem nowych traktorów Valtra',
  msg_page_rent_info_title: 'Wynajem traktorów Valtra',
  msg_no_ads_in_category_found: 'Wyników nie znaleziono',
  msg_ad_contact_us: 'Skontaktuj się',
  msg_error_wrong_image_format: 'Nieprawidłowy format obrazku',
  msg_error_file_size_too_large: 'Zbyt duży rozmiar pliku',
  msg_error_wrong_number: 'Nieprawidłowa liczba',
  msg_error_only_whole_numbers_allowed: 'Dozwolone są tylko liczby całkowite',
  msg_label_order_status_canceled: 'Anulowane',
  msg_label_order_status_payment_pending: 'Oczekiwanie na płatność',
  msg_label_order_status_declined: 'Odrzucone',
  msg_label_order_status_expired: 'Nieważne',
  msg_label_order_status_ended: 'Zakończony',
  msg_label_order_status_finished: 'Zakończony',
  msg_label_order_status_unfinished: 'Niezakończone',
  msg_order_summary_administration_fee: 'Opłata administracyjna',
  msg_order_summary_vat: 'VAT',
  msg_label_something_went_wrong: 'Nieoczekiwany błąd',
  msg_label_order_updated_status: 'Status zamówienia uległ zmianie!',
  msg_edit_auction_page_hero_title: 'msg_edit_auction_page_hero_title',
  msg_edit_auction_page_hero_subtitle: 'msg_edit_auction_page_hero_subtitle',
  msg_edit_auction_success_btn: 'msg_edit_auction_success_btn',
  msg_edit_auction_success_title: 'msg_edit_auction_success_title',
  msg_edit_auction_success_subtitle: 'msg_edit_auction_success_subtitle',
  msg_page_email_verification: 'msg_page_email_verification',
  msg_page_register_success: 'msg_page_register_success',
  msg_edit_auction_title: 'msg_edit_auction_title',
  msg_sejico_agro_header_link: 'Sejico Agro',
  msg_sejico_construction_header_link: 'Sejico Construction',
  msg_landing_page_hero_title_agro: 'Wynajem nowych traktorów Valtra',
  msg_landing_page_hero_title_constructions: 'Oferujemy szeroką gamę skandynawskich ciągników Valtra na wynajem',
  msg_landing_page_hero_subtitle_agro: 'Zobacz oferty',
  msg_landing_page_hero_subtitle_constructions: 'Wprowadź wyszukiwane hasło',
  msg_landing_page_hero_button: 'Wybierz kategorię z listy',
  msg_landing_search_form_search_input: 'Wyczyść',
  msg_landing_search_form_categories_select_input: 'Wyszukiwanie szczegółowe',
  msg_landing_clear_filters_button: 'Usunięcie konta użytkownika',
  msg_landing_detailed_search_button: 'Usuń',
  msg_delete_user_section_heading: 'Czy na pewno chcesz usunąć konto?',
  msg_delete_user_section_button: 'Konto zostanie usunięte w ciągu 30 dni',
  msg_delete_user_modal_title: 'Inne ogłoszenia',
  msg_delete_user_success_modal_subtitle: 'Wynajem maszyn',
  msg_other_ads_category_title: 'Usługi w zakresie maszyn rolniczych',
  msg_category_equipment_rent: 'Maszyny budowlane',
  msg_category_agricultural_machinery_services: 'Usługi w zakresie maszyn budowlanych',
  msg_category_construction_machinery: 'Najpopularniejsze kategorie',
  msg_category_construction_machinery_services: 'Zobacz',
  msg_landing_popular_cats_title: 'Popularne kategorie',
  msg_button_view_add: 'Pogląd',
  msg_featured_tab_daily_promotion: 'Codzienna promocja',
  msg_featured_tab_most_expensive: 'Najdroższy',
  msg_featured_tab_most_recent: 'Najnowszy',
  msg_featured_tab_most_popular: 'Najbardziej popularny',
  msg_featured_ads_section_agro_title: 'Serwis maszyn rolniczych',
  msg_featured_ads_section_construction_title: 'Serwis maszyn budowlanych',
  msg_loan_form_title: 'Kalkulator pożyczek',
  msg_loan_form_loan_sum: 'Wybierz kwotę pożyczki',
  msg_loan_form_how_to:
    'Jak kupić samochód na raty? Możesz pożyczyć do 30 000 zł. Wybierz żądaną miesięczną opłatę i wypełnij wniosek o finansowanie. Jeśli odpowiedź będzie pozytywna – przelejemy pieniądze na Twoje konto. Nie ma ograniczeń co do wieku samochodu i możesz go wybrać u dowolnego dealera, a ubezpieczenie CASKO nie jest wymagane. Możesz go zwrócić przed terminem bez dodatkowych opłat, np. po sprzedaży samochodu.',
  msg_loan_form_monthly_payment: 'Wybierz płatność miesięczną:',
  msg_loan_form_loan_price: 'Cena Twojej pożyczki:',
  msg_loan_form_contact: 'Jak się z Tobą skontaktować?',
  msg_loan_form_phone: 'Przez telefon',
  msg_loan_form_email: 'E-mail',
  msg_loan_form_enter_email: 'Wpisz swój adres e-mail',
  msg_loan_form_enter_phone: 'Wprowadź swój numer telefonu',
  msg_loan_form_get_offer_label: 'Uzyskaj ofertę',
  msg_loan_form_example:
    'Przykładowo, jeśli kwota udzielonego kredytu wynosi 18 000 zł, to umowa zawarta jest na 72 miesiące. na okres, oprocentowanie zmienne w skali roku - 3,5% + 6 miesięcy. EURIBOR (4,074%), prowizja za zawarcie umowy – 3%, miesięczna opłata administracyjna – 9,5 zł, BVKKMN – 10,13%, całkowita kwota do zapłaty – 23 807,84 zł, opłata miesięczna – 330,66 zł. Możesz otrzymać inne warunki kredytu online, które zależą od oceny Twojej zdolności kredytowej i ryzyka przez bank.',
  msg_mobile_header_favorite_ads: 'Ulubione reklamy',
  msg_mobile_header_activity: 'Powiadomienia',
  msg_advert_loan_form_success_message: 'Żądanie zostało wysłane pomyślnie. Skontaktujemy się z Tobą wkrótce.',
  msg_header_link_sejico_finance_title: ' Sejico Finance',
  msg_label_default_date_input_placeholder: 'Wybierz datę',
  msg_error_loan_duration_cannot_be_greater:
    'Czas trwania pożyczki nie może być dłuższy niż maksymalny czas trwania pożyczki',
  msg_error_loan_amount_cannot_be_greater: 'Kwota pożyczki nie może być większa niż maksymalna kwota pożyczki',
  msg_newsletter_section_title: 'Biuletyn',
  msg_newsletter_section_subtitle: 'Zapisz się do newslettera Sejico - otrzymuj informacje i oferty jako pierwszy!',
  msg_newsletter_section_email_input_placeholder: 'Wpisz adres e-mail',
  msg_subscribe_button: 'Subskrybuj',
  msg_newsletter_section_error: 'Wystąpił błąd',
  msg_newsletter_section_subscribe_success: 'Zapisano do newslettera!',
  msg_make: 'Marka / model',
  msg_model: 'Model',
  msg_year_of_manufacture: 'Rok produkcji',
  msg_defects: 'Wady',
  msg_make_date: 'Data produkcji',
  msg_type: 'Typ',
  msg_engine_make: 'Producent silników',
  msg_gross_weight: 'Waga całkowita',
  msg_about_page_title: 'O nas',
  msg_about_page_subtitle:
    'Naszą misją jest łączenie rolników i przedsiębiorstw w celu współpracy w zakresie zakupu/sprzedaży usług i/lub towarów.',
  msg_about_page_advantage_title_1: 'Wynajmij technika Sejico',
  msg_about_page_advantage_subtitle_1:
    'Długoterminowy i krótkoterminowy wynajem ciągników od tygodnia do roku. Obecnie oferujemy do wynajęcia ciągniki Valtra o mocy od 170 KM do 300 KM.',
  msg_about_page_advantage_title_2: 'Uzyskaj usługę od innych rolników',
  msg_about_page_advantage_subtitle_2:
    'W razie potrzeby skorzystania z usług innych rolników. Z pomocą Sejico znajdziesz innych rolników, którzy pomogą Ci wykonać potrzebne prace rolnicze.',
  msg_about_page_advantage_title_3: 'Świadcz usługi innym rolnikom',
  msg_about_page_advantage_subtitle_3:
    'W czasie wolnym od pracy w swoim gospodarstwie mogę zarabiać, świadcząc usługi innym rolnikom. Sejico – pośredniczy i zapewnia rolnikom warunki do wynajmu własnego sprzętu i wykonywania różnych prac. działa.',
  msg_about_page_benefits_section_title: 'Sejco - miejsce spotkań rolników!',
  msg_about_page_benefits_section_subtitle:
    'Sejico zapewnia korzyści rolnikom świadczącym usługi usługi lub wypożyczenie sprzętu:',
  msg_about_page_benefits_section_benefit_1:
    'Efektywne wykorzystanie techniki prowadzi do znacznie szybszego zwrotu kosztów.',
  msg_about_page_benefits_section_benefit_2: 'Zapewniona jest możliwość pracy tylko z zaufanymi klientami.',
  msg_about_page_benefits_section_benefit_3:
    'Możliwość oszczędzania bez kupowania nowego sprzętu, jeśli jest on używany irracjonalnie.',
  msg_about_page_benefits_section_benefit_4:
    'Krótkoterminowy wynajem sprzętu umożliwi Państwu wykonanie prac w przypadku awarii własnego sprzętu.',
  msg_about_page_benefits_section_benefit_5:
    'Wynajęcie lub wynajęcie usługi daje możliwość przetestowania sprzętu i najnowszych technologii przed dokonaniem nowego zakupu.',
  msg_landing_partners_section_title: 'Partnerzy',
  msg_general_order_min_info_part_1:
    'Nie znalazłeś interesującej Cię usługi lub chcesz zamówić usługę rolniczą? Wypełnij ten formularz, aby otrzymać najlepszą ofertę.',
  msg_general_order_min_info_part_2:
    'Sejico.pl pośredniczy w świadczeniu usług i gwarantuje terminowe wykonanie prac oraz płatności. Usługa będzie świadczona za pomocą sprzętu Sejico lub przy pomocy partnerów Sejico/innych uczestników platformy. Sejico oferuje możliwość odroczenia płatności.',
  msg_general_order_condition_full_payment: 'Bezzwłocznie (płatność 80% z góry, 20% bezpośrednio po wykonaniu prac)',
  msg_general_order_condition_fifty_fifty_split:
    '14 odroczenie (50% z góry, 50% po zakończeniu pracy w ciągu 14 dni) +5% opłaty za odroczenie',
  msg_general_order_condition_after_thirty_days_with_advance:
    '30-tego odroczenie (20% z góry, 80% po zakończeniu pracy w ciągu 30 dni) +9% opłata za odroczenie',
  msg_general_order_create_order_success: 'Zapytanie zostało złożone',
  msg_general_order_create_order_success_subtext: 'Otrzymaliśmy Twoje zapytanie i wkrótce się z Tobą skontaktujemy',
  msg_general_order_link: 'Formularz zamówienia',
  msg_general_order_acceptance_link: 'Formularz przyjęcia zamówienia',
  msg_general_order_field_amount: 'Ilość',
  msg_general_order_field_amount_placeholder: 'Podaj ilość',
  msg_general_order_field_field_size_placeholder: 'Laukas {{fieldNumber}} (ha)',
  msg_general_order_field_number_of_fields_placeholder: 'Ilość pól',
  msg_general_offer_offer_amount_display: 'Ilość:',
  msg_general_offer_offer_fields_display: 'Pola:',
  msg_general_order_field_location_title: 'Wskaż, gdzie potrzebujesz usługi',
  msg_general_order_field_location_placeholder: 'Miejsce',
  msg_general_order_field_label_select_market_price: 'Wybierz cenę z zakresu rynkowego',
  msg_general_order_field_payment_condition: 'Wybierz termin płatności:',
  msg_general_order_field_payment_condition_label: 'Wybierz termin płatności:',
  msg_general_order_field_payment_condition_explainer:
    'Sejico oferuje możliwość uzyskania odroczenia płatności. Prowizja Sejico zależy od długości okresu odroczenia. Prowizja Sejico może wynosić 2,5%, 5% lub 9%.',
  msg_general_order_full_price_display: 'Pełna cena:',
  msg_general_order_field_start_date: 'Data od',
  msg_general_order_field_end_date: 'Data do',
  msg_general_order_field_name: 'Imię',
  msg_general_order_field_surname: 'Nazwisko',
  msg_general_order_field_amount_type: 'Typ',
  msg_general_order_field_additional_info: 'Dodatkowe informacje',
  msg_general_order_field_terrain_type: 'Rodzaj terenu / Rodzaj ziemi',
  msg_general_order_field_market_price_explainer:
    'Cena usługi jest wstępna, a ostateczną cenę poznasz, gdy zostaną ocenione wszystkie czynniki mające na nią wpływ',
  msg_general_order_error_invalid_total_price: 'Nieprawidłowa cena całkowita',
  msg_general_order_error_invalid_terrain_type: 'Nieprawidłowy typ terenu',
  msg_general_order_error_end_date_musts_be_before_start_date: 'Data zakończenia musi być przed datą rozpoczęcia',
  msg_general_order_error_invalid_general_order: 'Nieprawidłowe zamówienie',
  msg_general_order_field_option_label_ha: 'ha',
  msg_general_offer_field_category_title: 'Wybierz potrzebną usługę rolniczą',
  msg_general_offer_field_category_placeholder: 'Usługa rolnicza',
  msg_accept_general_order_page_title: 'Potwierdzenie zamówienia',
  msg_general_offer_create_order_success: 'Zapytanie zostało złożone',
  msg_general_offer_create_order_success_subtext: 'Otrzymaliśmy Twoje zapytanie i wkrótce się z Tobą skontaktujemy',
  msg_general_offer_field_radio_agree_with_price: 'Zgadzam się z ceną',
  msg_general_offer_field_radio_offer_your_price: 'Zaproponuj swoją cenę',
  msg_general_offer_field_offered_price_placeholder: 'Wprowadź swoją cenę (EUR)',
  msg_general_offer_condition_full_payment: 'Bezzwłocznie (płatne bezpośrednio po pracy) -9%',
  msg_general_offer_condition_fifty_fifty_split: '14 opóźnienie (po zakończeniu pracy w ciągu 14 dni) -5%',
  msg_general_offer_condition_after_thirty_days_with_advance:
    '30-tego opóźnienie (po zakończeniu prac w ciągu 30 dni) -2,5%',
  msg_general_offer_order_price_display: 'Cena zamówienia:',
  msg_general_offer_final_price_display: 'Cena końcowa:',
  msg_general_offer_terrain_type_display: 'Rodzaj terenu / Rodzaj ziemi:',
  msg_create_contractor_page_title: 'Forma wykonawcy',
  msg_create_contractor_page_link: 'Forma wykonawcy',
  msg_contractor_create_success: 'Zapytanie zostało złożone',
  msg_contractor_create_success_subtext: 'Otrzymaliśmy Twoje zapytanie i wkrótce się z Tobą skontaktujemy',
  msg_contractor_field_category_details_placeholder: 'Sprzęt przewidziany do prac',
  msg_contractor_field_working_area_ray_placeholder: 'Promień zakresu prac (km)',
  msg_contractor_field_category_price_type_eur_per_hour_placeholder: 'EUR za godzinę',
  msg_contractor_field_category_price_type_eur_per_hectare_placeholder: 'EUR za hektar',
  msg_contractor_field_category_price_placeholder: 'Cena',
  msg_contractor_field_category_price_type_placeholder: 'Rodzaj',
  msg_contractor_form_general_info:
    'Sejico daje możliwość świadczenia usług rolniczych dla innych rolników, firm i przedsiębiorstw. Jeśli możesz wykonać dowolną usługę rolniczą, zapraszamy do wypełnienia formularza WYKONAWCY, a zespół Sejico zaproponuje zlecenia na podstawie podanych przez Ciebie kryteriów.',
} as const;
